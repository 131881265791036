import React from 'react';
import './GalleryTemplate.css';
// import {ButtonBack, ButtonNext, CarouselProvider, DotGroup, ImageWithZoom, Slide, Slider} from "pure-react-carousel";
import {FaTimes} from "react-icons/fa";

class GalleryTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal:
                (<div id="myModal" className="modal" style={{display:'none'}}>
                    <span className="close"><FaTimes/></span>
                    <img className="modal-content" id="img01" alt={''}/>
                </div>)
        };
    }

    render() {
        return (
            <div className={'gallery_separator'}>
                <div className={'gallery_template'}>
                    <h1 className={'title'}>{this.props.title}</h1>
                    <p>{this.props.description}</p>
                    {/*{this.props.images.map((item, i) => {
                        return (
                            <div key={i} className={'carousel_container'}>
                                <br />
                                <CarouselProvider
                                    naturalSlideWidth={this.props.width[i]}
                                    naturalSlideHeight={this.props.height[i]}
                                    totalSlides={item.length}
                                    isPlaying={false}
                                    infinite={true}
                                    touchEnabled={item.length > 1}
                                    dragEnabled={item.length > 1}
                                >
                                    <div className={'carousel_content'}>
                                        <Slider>
                                            {item.map((item, i) => {
                                                return (
                                                    <Slide index={i}>
                                                        <ImageWithZoom hasMasterSpinner={false} src={item}/>
                                                    </Slide>
                                                )
                                            })}
                                        </Slider>
                                        {item.length > 1 ? <div>
                                            <ButtonBack className={'carousel_back'}><FaChevronLeft size={25}/></ButtonBack>
                                            <ButtonNext className={'carousel_next'}><FaChevronRight size={25}/></ButtonNext>
                                        </div> : <div />}
                                    </div>
                                    {item.length > 1 ? <div>
                                        <DotGroup/>
                                    </div> : <div />}
                                </CarouselProvider>
                                <br />
                            </div>
                        )})}*/}
                    <div className={'image_container'}>
                        {this.props.images.map((item, i) => {
                                if (item !== '') {
                                    return (
                                        <span key={i} className={'image_padding'}>
                                            <img className={'image_sm'} src={item} alt={''} onClick={() => {
                                                this.setState({
                                                    modal:
                                                        (<div id="myModal" className="modal" style={{display: 'block'}}>
                                                            <span className="close" onClick={() => {
                                                                this.setState({
                                                                    modal:
                                                                        (<div id="myModal" className="modal"
                                                                              style={{display: 'none'}}>
                                                                            <span className="close"><FaTimes/></span>
                                                                            <img className="modal-content" id="img01" alt={''}/>
                                                                        </div>)
                                                                });
                                                            }}><FaTimes/></span>
                                                            <img src={item} className="modal-content" id="img01" alt={''}/>
                                                        </div>)
                                                })
                                            }}/>
                                                    {this.state.modal}
                                        </span>
                                    )
                                } else {
                                    return <div/>
                                }
                            }
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default GalleryTemplate;

import React from 'react';
import './Navbar.css';
import {FaBars} from 'react-icons/fa';

class Navbar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {show : false};
    }

    render() {
      return (
        <ul className={'navbar'}>
            <li className={'home'}>
                <a className={this.props.currentNav === 'home' ? 'active' : ''} href={'/home'}>
                    Home
                </a>
            </li>
            <div className={this.state.show === true ? 'links show' : 'links'}>
                <li className={'gallery'}>
                    <a className={this.props.currentNav === 'gallery' ? 'active' : ''} href={'/gallery'} >
                        Gallery
                    </a>
                    <div className={'gallery_dropdown'}>
                        <a className={this.props.subNav === 'gallery_bookcases' ? 'active' : ''} href={'/gallery/bookcases'}>
                            Bookcases
                        </a>
                        <a className={this.props.subNav === 'gallery_radiator_covers' ? 'active' : ''} href={'/gallery/radiator_covers'}>
                            Radiator Covers
                        </a>
                        <a className={this.props.subNav === 'gallery_wardrobes' ? 'active' : ''} href={'/gallery/wardrobes'}>
                            Wardrobes
                        </a>
                        <a className={this.props.subNav === 'gallery_garden_rooms' ? 'active' : ''} href={'/gallery/garden_rooms'}>
                            Garden Rooms & Outdoor Living
                        </a>
                        <a className={this.props.subNav === 'gallery_all_aspects' ? 'active' : ''} href={'/gallery/all_aspects'}>
                            All Aspects of Joinery
                        </a>
                    </div>
                </li>
                <li className={'content'}>
                    <a className={this.props.currentNav === 'contact' ? 'active' : ''} href={'/contact'}>
                        Contact Us
                    </a>
                </li>
            </div>
            <li className={'hamburger_menu'} >
                <a href className="icon" onClick={(e) => {
                    e.preventDefault();
                    this.setState({show: ! this.state.show});
                    this.render();
                }}>
                    <i><FaBars /></i>
                </a>
            </li>
        </ul>
      );
    }

}

export default Navbar;

import React from 'react';
import './GardenRooms.css';
import GalleryTemplate from "../gallery template/GalleryTemplate";

class GardenRooms extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className={'page'}>
                <div className={'garden_rooms'}>
                    <h1 className={'pageTitle'}>Garden Rooms & Outdoor Living</h1>
                    <GalleryTemplate
                        title={'Garden Room'}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Garden Rooms/1/20200622_223848_1.jpg',
                            '/gallery/Garden Rooms/1/20200622_224714_1.jpg',
                            '/gallery/Garden Rooms/1/20200622_224216_1.jpg',
                            '/gallery/Garden Rooms/1/20200615_125146_1.jpg',
                        ]}/>
                    <GalleryTemplate
                        title={'Garden Lighting'}
                        description={''}
                        width={[3,4]}
                        height={[4,3]}
                        images={[
                            '/gallery/Garden Rooms/2/IMG-20200623-WA0006.jpg',
                            '',
                            '/gallery/Garden Rooms/2/20200620_150004_1.jpg',
                            '/gallery/Garden Rooms/2/20200622_224413_1.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={'Playhouse'}
                        description={''}
                        width={[3]}
                        height={[4]}
                        images={[
                            '/gallery/Garden Rooms/3/received_257602362007402.jpeg',
                            '/gallery/Garden Rooms/3/received_729539957589860.jpeg'
                        ]}/>
                    <GalleryTemplate
                        title={'Hardwood Decking'}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Garden Rooms/4/2005_0618_182308AA_1.JPG',
                            '/gallery/Garden Rooms/4/2005_0618_182356AA_1.JPG',
                            '/gallery/Garden Rooms/4/2005_0618_182539AA_1.JPG',
                            '/gallery/Garden Rooms/4/2005_0618_182702AA_1.JPG',
                            '/gallery/Garden Rooms/4/2005_0618_182742AA_1.JPG'

                        ]}/>
                    <GalleryTemplate
                        title={'Softwood Treated Decking'}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Garden Rooms/5/2001_0121_222433AA.JPG',
                            '/gallery/Garden Rooms/5/2001_0121_222528AA.JPG',
                            '/gallery/Garden Rooms/5/2001_0121_222921AA.JPG'
                        ]}/>
                        <GalleryTemplate
                        title={'Gazebo'}
                        description={''}
                        width={[4,3]}
                        height={[3,4]}
                        images={[
                            '/gallery/Garden Rooms/6/IMG-20210103-WA0005_1.JPG',
                            '/gallery/Garden Rooms/6/IMG-20210103-WA0002_1.JPG'
                        ]}/>
                </div>
            </div>
        );
    }
}

export default GardenRooms;

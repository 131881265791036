import React from 'react';
import './Contact.css';
import {FaPhone, FaEnvelopeOpenText} from 'react-icons/fa';
import Navbar from "../../navbar/Navbar";

class Contact extends React.Component {

    render() {

        return (
            <div className={'page'}>
                <Navbar currentNav={'contact'} subNav={''} />
                <div className={'contact'}>
                    <h1>Contact Details</h1>
                    <table className={'details'}>
                        <tbody>
                            <tr>
                                <td>
                                    <i className={'icon'}>
                                        <FaPhone size={50}/>
                                    </i>
                                </td>
                                <td>
                                    <div className={'contact_details'}>
                                        <h2>Phone</h2>
                                        <p>
                                            <a href={'tel:07752227944'}>07752227944</a>

                                        </p>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <i className={'icon'}>
                                        <FaEnvelopeOpenText size={50}/>
                                    </i>
                                </td>
                                <td>
                                    <div className={'contact_details'}>
                                        <h2>Email</h2>
                                        <p>
                                            <a href={'mailto:joe@prestigejoineryni.co.uk'}>joe@prestigejoineryni.co.uk</a>
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/*<h1>Contact Form</h1>*/}
                    {/*<form className={'contact_form'} name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action={'/#contact'}>*/}
                    {/*    <input type="hidden" name="form-name" value="contact" />*/}
                    {/*    <label htmlFor={'name'}>Full Name</label>*/}
                    {/*    <input type={'text'} name={'name'} />*/}
                    {/*    <label htmlFor={'email'}>Email Address</label>*/}
                    {/*    <input type={'email'} name={'email'} />*/}
                    {/*    <label htmlFor={'message'}>Message</label>*/}
                    {/*    <textarea name={'message'} />*/}
                    {/*    <input type={'submit'} value={'Submit'} />*/}
                    {/*</form>*/}
                </div>
            </div>
        );
    }
}

export default Contact;

import React from 'react';
import Home from "./home/Home";
import Gallery from "./gallery/Gallery";
import Contact from "./contact/Contact";
import './Main.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Navbar from "../navbar/Navbar";
import GardenRooms from "./gallery/garden rooms/GardenRooms";
import Bookcases from "./gallery/bookcases/Bookcases";
import RadiatorCovers from "./gallery/radiator covers/RadiatorCovers";
import Wardrobes from "./gallery/wardrobes/Wardrobes";
import AllAspects from "./gallery/all aspects/AllAspects";

class Main extends React.Component {

    render() {
        return (
            <div className={'main'}>
                <Router>
                    <div>
                        <Switch>
                            <Route path="/contact">
                                <Contact />
                            </Route>
                            <Route path="/gallery">
                                <Switch>
                                    <Route path={'/gallery/bookcases'}>
                                        <Navbar currentNav={'gallery'} subNav={'gallery_bookcases'}/>
                                        <Bookcases />
                                    </Route>
                                    <Route path={'/gallery/radiator_covers'}>
                                        <Navbar currentNav={'gallery'} subNav={'gallery_radiator_covers'}/>
                                        <RadiatorCovers />
                                    </Route>
                                    <Route path={'/gallery/wardrobes'}>
                                        <Navbar currentNav={'gallery'} subNav={'gallery_wardrobes'}/>
                                        <Wardrobes />
                                    </Route>
                                    <Route path={'/gallery/garden_rooms'}>
                                        <Navbar currentNav={'gallery'} subNav={'gallery_garden_rooms'}/>
                                        <GardenRooms />
                                    </Route>
                                    <Route path={'/gallery/all_aspects'}>
                                        <Navbar  currentNav={'gallery'} subNav={'gallery_all_aspects'}/>
                                        <AllAspects />
                                    </Route>
                                    <Route path={'/gallery'}>
                                        <Gallery />
                                    </Route>
                                </Switch>
                            </Route>
                            <Route path="/home">
                                <Home />
                            </Route>
                            <Redirect to={'/home'} />
                        </Switch>
                    </div>
                </Router>
            </div>
        );
    }
}

export default Main;

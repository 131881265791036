import React from 'react';
import './Gallery.css';
import Navbar from "../../navbar/Navbar";

class Gallery extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className={'page'}>
                <Navbar currentNav={'gallery'} subNav={''}/>
                <div className={'gallery'}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={'image_links'}>
                                    <a className={'bookcases'} href={'/gallery/bookcases'}>
                                        <img src={'/gallery/Bookcases/20200624_220509_1.jpg'} alt={'A bookcase.'}/>
                                        <div className={'image_description'}>
                                            <div className={'text'}>
                                                Bookcases
                                            </div>
                                        </div>
                                    </a>
                                </td>
                                <td className={'image_links'}>
                                    <a className={'radiator_covers'} href={'/gallery/radiator_covers'}>
                                        <img src={'/gallery/Radiator Covers/1/20200624_221743_1.jpg'} alt={'A radiator cover.'}/>
                                        <div className={'image_description'}>
                                            <div className={'text'}>
                                                Radiator Covers
                                            </div>
                                        </div>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className={'image_links'}>
                                    <a className={'wardrobes'} href={'/gallery/wardrobes'}>
                                        <img src={'/gallery/wardrobes/2/20200624_221252_1.jpg'} alt={'A wardrobe.'}/>
                                        <div className={'image_description'}>
                                            <div className={'text'}>
                                                Wardrobes
                                            </div>
                                        </div>
                                    </a>
                                </td>
                                <td className={'image_links'}>
                                    <a className={'garden_rooms'} href={'/gallery/garden_rooms'}>
                                        <img src={'/gallery/Garden Rooms/1/20200622_224714_1.jpg'} alt={'A garden room.'}/>
                                        <div className={'image_description'}>
                                            <div className={'text'}>
                                                Garden Rooms & Outdoor Living
                                            </div>
                                        </div>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td className={'image_links'}>
                                    <a className={'all_aspects'} href={'/gallery/all_aspects'}>
                                        <img src={'/gallery/All Aspects/IMG-20200623-WA0005.jpg'} alt={'Other aspects of joinery.'}/>
                                        <div className={'image_description'}>
                                            <div className={'text'}>
                                                All Aspects of Joinery
                                            </div>
                                        </div>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Gallery;

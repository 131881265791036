import React from 'react';
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
    Image,
    DotGroup
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './Home.css';
import {FaChevronRight, FaChevronLeft} from 'react-icons/fa';
import Navbar from "../../navbar/Navbar";

class Home extends React.Component {

    render() {

        return (
            <div className={'page'}>
                <Navbar currentNav={'home'} subNav={''}/>
                <div className={'home'}>
                    <div>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <img className={'logo'} src={'logo.png'} alt={''}/>
                                </td>
                                <td>
                                    <h1>Prestige Joinery NI</h1>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className={'carousel_container'}>
                        <CarouselProvider
                            naturalSlideWidth={4}
                            naturalSlideHeight={3}
                            totalSlides={3}
                            isPlaying={true}
                            infinite={true}
                        >
                            <div className={'carousel_content'}>
                                <Slider>
                                    <Slide index={0}>
                                        <Image hasMasterSpinner={false} src={'/gallery/Bookcases/20200624_220509_1.jpg'}/>
                                    </Slide>
                                    <Slide index={1}>
                                        <Image hasMasterSpinner={false} src={'/gallery/Radiator Covers/1/20200624_221743_1.jpg'}/>
                                    </Slide>
                                    <Slide index={2}>
                                        <Image hasMasterSpinner={false} src={'/gallery/Garden Rooms/1/20200622_224714_1.jpg'}/>
                                    </Slide>
                                </Slider>
                                <ButtonBack className={'carousel_back'}><FaChevronLeft size={25}/></ButtonBack>
                                <ButtonNext className={'carousel_next'}><FaChevronRight size={25}/></ButtonNext>
                            </div>
                            <DotGroup/>
                        </CarouselProvider>
                    </div>

                        <h2>What we do</h2>
                        <ul className={'gallery_links'}>
                            <li>
                                <a href={'/gallery/bookcases'}>Bookcases</a>, traditional and contemporary to suit your style.
                            </li>
                            <li>
                                <a href={'/gallery/radiator_covers'}>Radiator covers</a>.
                            </li>
                            <li>
                                <a href={'/gallery/wardrobes'}>Wardrobes.</a>
                            </li>
                            <li>
                                <a href={'/gallery/garden_rooms'}>Garden Rooms.</a>
                            </li>
                            <li>
                                <a href={'/gallery/all_aspects'}>All aspects of joinery.</a>
                            </li>
                        </ul>
                </div>
            </div>
        );
    }
}

export default Home;

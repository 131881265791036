import React from 'react';
import './Wardrobes.css';
import GalleryTemplate from "../gallery template/GalleryTemplate";

class Wardrobes extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className={'page'}>
                <div className={'wardrobes'}>
                    <h1 className={'pageTitle'}>Wardrobes</h1>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Wardrobes/2/20200615_125510_1.jpg',
                            '/gallery/Wardrobes/2/20200624_221252_1.jpg'

                        ]}/>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Wardrobes/1/20200615_125324_1.jpg'
                        ]}/>
                </div>
            </div>
        );
    }
}

export default Wardrobes;

import React from 'react';
import './AllAspects.css';
import GalleryTemplate from "../gallery template/GalleryTemplate";

class AllAspects extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className={'page'}>
                <div className={'all_aspects'}>
                    <h1 className={'pageTitle'}>All Aspects of Joinery</h1>
                    <GalleryTemplate
                        title={'Wooden False Beams'}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/All Aspects/IMG-20200623-WA0004.jpg',
                            '/gallery/All Aspects/IMG-20200623-WA0005.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={'Dormers'}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/All Aspects/2005_0319_175544AA_1.JPG',
                            '/gallery/All Aspects/2005_0319_175600AA_1.JPG',
                            '/gallery/All Aspects/2005_0319_175816AA_1.JPG'
                        ]}/>
                    <GalleryTemplate
                        title={'Doors'}
                        description={''}
                        width={[4,3]}
                        height={[3,4]}
                        images={[
                            '/gallery/All Aspects/2005_0331_171431AA_1.JPG',
                            '',
                            '/gallery/All Aspects/IMG-20200628-WA0000.JPG',
                            '/gallery/All Aspects/IMG-20210117-WA0001.jpg',
                            '/gallery/All Aspects/IMG-20210117-WA0003_1.jpg',
                            '/gallery/All Aspects/IMG-20210117-WA0004_1.jpg'

                        ]}/>
                    <GalleryTemplate
                        title={'Bespoke Bars and Wall Mounted Optic Drinks Display'}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Garden Rooms/1/20200622_224216_1.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={'Panelling'}
                        description={''}
                        width={[4,3]}
                        height={[3,4]}
                        images={[
                            '/gallery/All Aspects/IMG-20210117-WA0000_1.JPG',
                            '',
                            '/gallery/All Aspects/IMG-20210422-WA0000_1.JPG',
                            '/gallery/All Aspects/IMG-20210422-WA0001.JPG'
                        ]}/>
                </div>
            </div>
        );
    }
}

export default AllAspects;

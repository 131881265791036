import React from 'react';
import './Bookcases.css';
import GalleryTemplate from "../gallery template/GalleryTemplate";

class Bookcases extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className={'page'}>
                <div className={'bookcases'}>
                    <h1 className={'pageTitle'}>Bookcases</h1>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[2,2]}
                        height={[1,1]}
                        images={[
                            '/gallery/Bookcases/1/IMG-20200623-WA0001.jpg',
                            '/gallery/Bookcases/1/IMG-20200623-WA0000_1.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Bookcases/2/IMG-20200623-WA0003.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[3]}
                        height={[4]}
                        images={[
                            '/gallery/Bookcases/3/IMG-20200625-WA0000_1.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[4,3]}
                        height={[3,4]}
                        images={[
                            '/gallery/Bookcases/9/IMG-20210422-WA0002_1.jpg',
                            '/gallery/Bookcases/9/IMG-20210422-WA0004.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Bookcases/11/IMG-20220121-WA0000.jpg',
                            '/gallery/Bookcases/11/IMG-20220121-WA0001.jpg',
                            '/gallery/Bookcases/11/IMG-20220119-WA0000.jpg',
                            '/gallery/Bookcases/11/IMG-20220119-WA0002_1.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Bookcases/13/IMG-20240512-WA0013.jpg',
                            '/gallery/Bookcases/13/IMG-20240512-WA0014.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={'False Chimney Breast with TV Insert'}
                        description={''}
                        width={[3]}
                        height={[4]}
                        images={[
                            '/gallery/Bookcases/5/IMG-20200918-WA0000.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={'Rustic Work Desk'}
                        description={''}
                        width={[4]}
                        height={[3]}
                        images={[
                            '/gallery/Bookcases/6/IMG-20210103-WA0003.JPG'
                        ]}/>
                    <GalleryTemplate
                        title={'Window Seat'}
                        description={''}
                        width={[3]}
                        height={[4]}
                        images={[
                            '/gallery/Bookcases/7/IMG-20210103-WA0004.JPG'
                        ]}/>
                    <GalleryTemplate
                        title={'Bootroom'}
                        description={''}
                        width={[3]}
                        height={[4]}
                        images={[
                            '/gallery/Bookcases/8/IMG-20210103-WA0000.JPG',
                            '/gallery/Bookcases/8/IMG-20210103-WA0001.JPG',
                            '/gallery/Bookcases/10/IMG-20210920-WA0000.jpg',
                            '/gallery/Bookcases/10/IMG-20220119-WA0001.jpg'
                        ]}/>
                    <GalleryTemplate
                        title={''}
                        description={''}
                        width={[3]}
                        height={[4]}
                        images={[
                            '/gallery/Bookcases/12/IMG-20220121-WA0002.JPG',
                            '/gallery/Bookcases/12/IMG-20220121-WA0003.JPG'
                        ]}/>
                </div>
            </div>
        );
    }
}

export default Bookcases;
